import type { IMoviesParams, IPaymentMovieParams, IQueryParams } from '~/services/types';
import type {
  IBanner,
  ICollections,
  ICollectionSlug,
  IFilters,
  IGenreCategory,
  IMovie,
  IMovieCard,
  IPerson,
  IPremiers,
  ITrailer,
  IViewedMovies,
} from '~/types/movies';
import { useIQuery } from '~/services/useQuery';
import type { MaybeRef } from 'vue';
import { useIMutation } from '~/services/useMutation';
import type { IFetchUser, IPaymentRedirect, ISetRating } from '~/types/common';
import { useNuxtApp } from '#app';
import { movieCacheKeys, noveltyMoviesBaseKeys } from '~/consts/movieCacheKeys';

export const getBanners = (options?: IQueryParams) =>
  useIQuery<IBanner[]>('v4/slider-banners/', { ...options, pagination: true });

export const getPopularMovies = (options?: IQueryParams) =>
  useIQuery<IMovieCard[]>('v4/popular/', {
    ...options,
    pagination: true,
    key: movieCacheKeys.popularFilms,
  });

export const getNoveltyMovies = (options?: IQueryParams<MaybeRef<IMoviesParams>>) => {
  const url = 'v4/films/';

  return useIQuery<IMovieCard[]>(url, {
    ...options,
    pagination: true,
    key: [].concat(options?.key ?? [url, options?.params]).concat(noveltyMoviesBaseKeys),
  });
};

export const getMoviesCollections = (options?: IQueryParams) =>
  useIQuery<ICollections[]>('v5/web/film-collections/', { ...options, pagination: true });

export const getMoviesCollectionsBySlug = (slug: string, options?: IQueryParams) =>
  useIQuery<ICollections[]>(`v5/web/film-collections/${slug}/`, { ...options, pagination: true });

export const getMovie = (slug: string, options?: IQueryParams) =>
  useIQuery<IMovie>(`v4/films/${slug}/`, { ...options, pagination: false, key: slug });

export const getRelatedMovies = (slug: string, options?: IQueryParams) =>
  useIQuery<IMovieCard[]>(`v4/films/${slug}/same/`, { ...options, pagination: true });

export const getPremiers = (options?: IQueryParams) =>
  useIQuery<IPremiers[]>('v5/web/content/world-premieres/', {
    ...options,
    pagination: true,
    key: movieCacheKeys.worldPremieres,
  });

export const getViewedMovies = (options?: IQueryParams) =>
  useIQuery<IViewedMovies[]>('v4/user/was-viewing/', { ...options, pagination: true });

export const getWatchedMovies = (options?: IQueryParams) =>
  useIQuery<IViewedMovies[]>('v5/web/user/watched/', { ...options, pagination: true });

export const getCollection = (slug: string, options?: IQueryParams) =>
  useIQuery<ICollectionSlug>(`v5/web/film-collections/${slug}/`, { ...options, pagination: false });

export const getCollectionList = (slug: string, options?: IQueryParams) =>
  useIQuery<IMovieCard[]>(`v5/web/film-collections/${slug}/`, {
    ...options,
    pagination: true,
    torrent: true,
    key: [...movieCacheKeys.filmCollections, `${movieCacheKeys.filmCollections}-${slug}`],
  });

export const getCrewById = (id: string | number, options?: IQueryParams) =>
  useIQuery<IPerson>(`v4/person/${id}/`, { ...options, pagination: false });

export const getMoviesFilters = (options?: IQueryParams) =>
  useIQuery<IFilters>(`v4/films/filters/`, { ...options, pagination: false });

export const getFavorites = (options?: IQueryParams<MaybeRef<IMoviesParams>>) =>
  useIQuery<IMovieCard[]>('v4/favorites/', {
    ...options,
    pagination: true,
    key: movieCacheKeys.userFavoriteMovies,
  });

export const getGenres = (options?: IQueryParams) =>
  useIQuery<IGenreCategory[]>('v5/web/genres/', { ...options, pagination: true, params: { page_size: '50' } });

export const setRatingMovie = (options?: IQueryParams) =>
  useIMutation<ISetRating>('v4/user/rating/', { ...options, method: 'post' });

export const setFavoriteMovie = (options?: IQueryParams) =>
  useIMutation<ISetRating>('v4/favorites/', { ...options, method: 'post' });

export const removeFavoriteMovie = (id?: number, options?: IQueryParams) =>
  useIMutation<ISetRating>(`v4/favorites/${id}/`, { ...options, method: 'delete' });

export const buyFilmByCard = (slug: string, obtainType: 'purchase' | 'hd_lease' | 'sd_lease', payload: {}) => {
  return useNuxtApp().$apiFetch<IPaymentRedirect>(`v4/films/${slug}/obtain/${obtainType}/`, {
    method: 'post',
    body: payload,
  });
};

export const buyFilmByBalance = (payload: IPaymentMovieParams) => {
  return useNuxtApp().$apiFetch<IPaymentRedirect>(`v4/balance/obtain-film/`, {
    method: 'post',
    body: payload,
  });
};

export const deleteTimelineById = (id: number) =>
  useNuxtApp().$apiFetch<IPaymentRedirect>(`v4/timelines/${id}/`, {
    method: 'delete',
  });

export const fetchTrailer = (slug: string) => useIQuery<ITrailer[]>(`v5/web/trailers/${slug}/`, { pagination: true });

export const fetchOneTrailer = (slug: string) =>
  useIQuery<ITrailer[]>(`v5/web/trailers/${slug}/`, { pagination: true });
