export const movieFavoriteParamUpdKey = 'movie-favorite-update';

export const noveltyMoviesBaseKeys = [movieFavoriteParamUpdKey];

export const movieCacheKeys = {
  worldPremieres: ['world-premieres', movieFavoriteParamUpdKey],
  popularFilms: ['popular-films', movieFavoriteParamUpdKey],
  filmCollections: ['film-collections', movieFavoriteParamUpdKey],
  userFavoriteMovies: ['user-favorite-movies'],
};
